<template>
	<b-modal id="edit-asset-pool-distribution" size="lg" title="Edit Asset Pool Distribution" ref="modal" ok-title="Save"
		@ok="handleOk" @show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<!-- Company, Connection, Asset Type -->
				<b-row class="my-2">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Company">
							<strong>{{ form.company }}</strong>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Connection">
							<strong>{{ form.connectedCompany + ' - ' + form.connectedStorageLocation }}</strong>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Asset Type">
							<strong>{{ form.assetType }}</strong>
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Asset Limit, Total Adjustments, Notes -->
				<b-row class="my-2">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Asset Limit" description="Maximum number of assets of this connection">
							<span class="numFont"><strong>{{ form.assetLimit }}</strong></span>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Total Adjustment" :description="'Current Total: ' + form.total.toLocaleString()">
							<b-input-group>
								<b-input-group-prepend>
									<b-button id="less-total" name="less-total" variant="outline-secondary" @click="decrementValue()">&nbsp; - &nbsp;</b-button>
								</b-input-group-prepend>

								<b-form-input id="total" name="Total Adjustment" type="number" step="1" v-model.number="addTotal"
									v-validate="{ required: true, min_value: -1000000, max_value: 1000000 }" placeholder="0" 
									class="text-center" @keydown="blockInvalidCharacters" />

								<b-input-group-append>
									<b-button id="add-total" name="add-total" variant="outline-secondary" @click="incrementValue()">&nbsp; + &nbsp;</b-button>
								</b-input-group-append>
							</b-input-group>     
							
							<span v-show="errors.has('Total Adjustment')" class="help-block">
								{{ errors.first('Total Adjustment') }}
							</span>
						</b-form-group>
						<div class="instruction mb-2">
							You can increase the total count by entering a positive number (i.e. 1). <br/>
							You can decrease the total count by entering a negative number (i.e. -1)
						</div>
						<div class="adjusted-total mb-2" v-show="addTotal !== 0">
							Adjusted Total: <strong>{{ adjustedTotal.toLocaleString() }}</strong>
						</div>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Notes">
							<b-form-textarea name="Notes" type="text" v-model="form.notes" maxlength="200" :rows="3"
								placeholder="Add more details here..." v-validate="{
									required: true,
									regex: remarksRegex
								}" />
							<span v-show="errors.has('Notes')" class="help-block">
								{{ errors.first('Notes') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Utils
import { AssetPoolDistributionUtil } from '@/utils/assetPoolDistributionUtil';
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import assetPoolDistributionApi from '@/api/assetPoolDistributionApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'edit-asset-pool-distribution',
	components: {
		Loading,
	},
	props: {
        allCompaniesObj: {
			type: Object,
			required: true,
		},
		allConnectedCompaniesObj: {
			type: Object,
			required: true,
		},
		allAssetTypesObj: {
			type: Object,
			required: true,
		},
    },
	data() {
		return {
			form: { ...AssetPoolDistributionUtil.getDefaultDistributionObj() },
			addTotal: 0,
			adjustedTotal: 0,

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		addTotal: {
			handler(newValue) {
				this.computeAdjustedTotal(newValue);
			},
			deep: true,
			immediate: true
		}
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
	},
	methods: {
		blockInvalidCharacters(event) {
			// Check if the key pressed corresponds to one of the blocked characters
			if (event.key === "Enter" || event.key === '+' || event.key === 'e') {
				// Prevent the form from submitting when pressing Enter
				// Prevent the default behavior (typing the character)
				event.preventDefault();
			}
		},
		decrementValue() {
			if (this.addTotal > 0) {
				this.addTotal -= 1;
			}
		},
		incrementValue() {
			if (this.addTotal < 1000000) {
				this.addTotal += 1;
			}
		},
		computeAdjustedTotal(addTotal){
			let total = this.form.total ? parseInt(this.form.total) : 0;
			let adjustment = addTotal !== 0 ? parseInt(addTotal) : 0;
			this.adjustedTotal = total + adjustment;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			} else if (this.adjustedTotal < 0) {
				this.$toaster.warning('Invalid Input: Negative adjusted total is not allowed.');
				// hide loading indicator
				this.isLoading = false;
				return;
			} else if (this.addTotal === 0) {
				this.$toaster.warning('Invalid Input: Adjustment value must not be equal to 0.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			await this.handleSubmit();
		},
		updateFormValues(form) {
			let distribution = { ...form };

			distribution.addTotal = this.addTotal;
			distribution.notes = ValidationUtil.removeExcessWhiteSpace(form.notes);
			distribution.dateUpdated = DateUtil.getCurrentTimestamp();
			distribution.updatedBy = this.currUserId;

			return AssetPoolDistributionUtil.cleanupFields(distribution);
		},
		getParam() {
			return {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				assetPoolDistribution: this.updateFormValues(this.form),
			};
		},
		async handleSubmit() {
			try {
				// show loading indicator
				this.isLoading = true;

				let { data } = await assetPoolDistributionApi.saveAssetPoolDistribution(
					this.getParam()
				);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveAssetPoolDistribution', data.assetPoolDistribution);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error updating Asset Pool Distribution "${this.form.company}". Please try again.`);
				}

			} catch (error) {
				this.$toaster.error(`Error updating Asset Pool Distribution "${this.form.company}". Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}			
		},
		onReset() {
			/* Reset our form values */
			let currAPD = this.$store.getters.currAssetPoolDistribution;
			this.form = { ...currAPD };

			if (!currAPD.type || currAPD.type.length === 0) {
				let companyObj = this.allCompaniesObj[currAPD.companyId];
				let connectedCompanyObj = this.allConnectedCompaniesObj[currAPD.connectedCompanyId];
				let assetTypeObj = this.allAssetTypesObj[currAPD.assetTypeId];
				this.form.type = AssetPoolDistributionUtil.getDistributionType(currAPD, companyObj, connectedCompanyObj, assetTypeObj.originId);
			}
			
			this.form.assetLimit = currAPD.assetLimit ? parseInt(currAPD.assetLimit) : 0;
			this.form.total = currAPD.total ? parseInt(currAPD.total) : 0;
			this.addTotal = 0;

			// Reset Notes for Reason for Adjustment
			this.form.notes = '';

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style scoped>
.instruction {
	font-size: 10px !important;
	color: #f18f01 !important;
}

.adjusted-total {
	margin-top: 10px;
	font-size: 12px !important;
	color: #122c91 !important;
}
</style>