<template>
	<b-modal id="update-asset-limit" :title="title" ok-title="Save" cancel-title="Cancel" ref="modal" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message">
			Enter the maximum number of assets for <strong>{{ distribution.connectedCompany }}</strong>
		</div>
		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-row class="mt-4 px-2">
                <b-col sm="5" class="text-center">
                    <strong>ASSET TYPE</strong>
                </b-col>
                <b-col sm="7" class="text-center">
                    <strong>ASSET LIMIT</strong>
                </b-col>
            </b-row>
            <b-row class="my-2 px-2">
                <b-col sm="5" class="text-center">
                    {{ distribution.assetType }}
                </b-col>
                <b-col sm="7" class="text-center">
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-button id="less-limit" name="less-limit" variant="outline-secondary" @click="decrementLimit">&nbsp; - &nbsp;</b-button>
                        </b-input-group-prepend>

                        <b-form-input id="asset-limit" name="Asset Limit" type="number" step="1" v-model.number="distribution.assetLimit" 
                            v-validate="{ required: true, min_value: 1, max_value: 1000000 }" placeholder="0" 
							class="text-center" @keydown="blockInvalidCharacters"/>

                        <b-input-group-append>
                            <b-button id="add-limit" name="add-limit" variant="outline-secondary" @click="incrementLimit">&nbsp; + &nbsp;</b-button>
                        </b-input-group-append>
                    </b-input-group>                    
                    <span v-show="errors.has('Asset Limit')" class="help-block">
                        {{ errors.first('Asset Limit') }}
                    </span>
                </b-col>
            </b-row>
		</b-form>
	</b-modal>
</template>

<script>
// Utils
import { AssetPoolDistributionUtil } from '@/utils/assetPoolDistributionUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import assetPoolDistributionApi from '@/api/assetPoolDistributionApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'update-asset-limit',
	components: {
		Loading,
	},
    props: {
        allCompaniesObj: {
			type: Object,
			required: true,
		},
		allConnectedCompaniesObj: {
			type: Object,
			required: true,
		},
		allAssetTypesObj: {
			type: Object,
			required: true,
		}
    },
	data() {
		return {
			distribution: {},
			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return this.distribution.type + ' Limit';
		},
		disableConfirmButtons() {
			return this.isLoading;
		}
	},
	mounted() {
		EventBus.$on('onUpdateAssetLimit', (distribution) => {
			if (!_.isEmpty(distribution)) {
				this.distribution = { ...distribution };

				if (!this.distribution.assetLimit) {
					this.distribution.assetLimit = 0;
				}
				
				if(!this.distribution.type || this.distribution.type.length === 0) {
					let companyObj = this.allCompaniesObj[distribution.companyId];
					let connectedCompanyObj = this.allConnectedCompaniesObj[distribution.connectedCompanyId];	
					let assetTypeObj = this.allAssetTypesObj[distribution.assetTypeId];
					this.distribution.type = AssetPoolDistributionUtil.getDistributionType(distribution, companyObj, connectedCompanyObj, assetTypeObj.originId);
				}
			}
		});
	},
	methods: {
		blockInvalidCharacters(event) {
			// Check if the key pressed corresponds to one of the blocked characters
			if (event.key === '+' || event.key === '-' || event.key === 'e') {
				// Prevent the default behavior (typing the character)
				event.preventDefault();
			}
		},
		decrementLimit() {
			if (this.distribution.assetLimit > 0) {
				this.distribution.assetLimit -= 1;
			}
		},
		incrementLimit() {
			if (this.distribution.assetLimit < 1000000) {
				this.distribution.assetLimit += 1;
			}
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}
			
            await this.handleSubmit();
		},
		updateFormValues(form) {
			let distribution = { ...form };

			distribution.addTotal = 0;
			distribution.notes = 'Asset Limit was updated by ' + this.currUserId;
			distribution.dateUpdated = DateUtil.getCurrentTimestamp();
			distribution.updatedBy = this.currUserId

			return AssetPoolDistributionUtil.cleanupFields(distribution);
		},
		getParam() {
			return {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				assetPoolDistribution: this.updateFormValues(this.distribution),
			};
		},
        async handleSubmit() {
			try {
                // show loading indicator
				this.isLoading = true;

				let { data } = await assetPoolDistributionApi.saveAssetPoolDistribution(
					this.getParam()
				);

				if (data.isSuccess) {
					this.$toaster.success('Updating Asset Limit was successful.');
					EventBus.$emit('onCloseSaveAssetPoolDistribution', data.assetPoolDistribution);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error updating asset limit "${this.distribution.connectedCompany}". Please try again.`);
				}

			} catch (error) {
				this.$toaster.error(`Error updating asset limit for "${this.distribution.connectedCompany}". Please try again.`);
			} finally {
                // hide loading indicator
                this.isLoading = false; 
            }
        }
	},

	beforeDestroy() {
		EventBus.$off('onUpdateAssetLimit');
	},
};
</script>