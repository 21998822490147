<template>
	<b-modal id="add-asset-pool-distribution" size="lg" title="Add Asset Pool Distribution" ref="modal" ok-title="Save"
		@ok="handleOk" @show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<!-- Company -->
				<b-row class="my-2">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Company">
							<v-select name="Company" class="style-chooser" label="text" placeholder=" - Please select - " :options="companyOptions"
								:reduce="(company) => company.value" v-model="selCompany" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Company')" class="help-block">
								{{ 'This field is required' }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Connection -->
				<b-row class="my-2">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Connection" description="Company">
							<v-select name="Connected Company" class="style-chooser" label="text" placeholder=" - Please select - "
								:options="connectedCompanyOptions" :reduce="(company) => company.value"
								v-model="selConnectedCompany" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Connected Company')" class="help-block">
								{{ 'This field is required' }}
							</span>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group class="field-without-label" description="Storage Location">
							<v-select name="Connected Storage Location" class="style-chooser" label="text" placeholder=" - Please select - "
								:options="connectedStorageLocationOptions" :reduce="(loc) => loc.value"
								v-model="selConnectedStorageLocation" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a location
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Connected Storage Location')" class="help-block">
								{{ 'This field is required' }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Asset Type -->
				<b-row class="my-2">
					<b-col lg="8" md="8" sm="12">
						<b-form-group label="Asset Type">
							<v-select name="Asset Type" class="style-chooser" label="text" placeholder=" - Please select - "
								:options="assetTypeOptions" :reduce="(assetType) => assetType.value"
								v-model="selAssetType" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a asset type
									</em>
								</template>
							</v-select>

							<span v-show="errors.has('Asset Type')" class="help-block">
								{{ 'This field is required' }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Asset Limit, Total, Notes -->
				<b-row class="my-2" v-show="selAssetType && selAssetType.id !== null">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Asset Limit" description="Maximum number of assets of this connection">
							<b-input-group>
								<b-input-group-prepend>
									<b-button id="less-limit" name="less-limit" variant="outline-secondary" @click="decrementValue('assetLimit', 0)">&nbsp; - &nbsp;</b-button>
								</b-input-group-prepend>

								<b-form-input id="asset-limit" name="Asset Limit" type="number" step="1" v-model.number="form.assetLimit" 
									v-validate="{ required: true, min_value: 0, max_value: 1000000 }" placeholder="0" 
									class="text-center" @keydown="blockInvalidCharacters"/>

								<b-input-group-append>
									<b-button id="add-limit" name="add-limit" variant="outline-secondary" @click="incrementValue('assetLimit', 1000000)">&nbsp; + &nbsp;</b-button>
								</b-input-group-append>
							</b-input-group>                    
							<span v-show="errors.has('Asset Limit')" class="help-block">
								{{ errors.first('Asset Limit') }}
							</span>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12" v-show="isAllowedToEditTotal">
						<b-form-group label="Total" :description="'Current Total: ' + form.total.toLocaleString()">
							<b-input-group>
								<b-input-group-prepend>
									<b-button id="less-total" name="less-total" variant="outline-secondary" @click="decrementValue('addTotal', 0)">&nbsp; - &nbsp;</b-button>
								</b-input-group-prepend>

								<b-form-input id="total" name="Total" type="number" step="1" v-model.number="form.addTotal"
									v-validate="{ required: true, min_value: 0, max_value: 1000000 }" placeholder="0" 
									class="text-center" @keydown="blockInvalidCharacters" />

								<b-input-group-append>
									<b-button id="add-total" name="add-total" variant="outline-secondary" @click="incrementValue('addTotal', 1000000)">&nbsp; + &nbsp;</b-button>
								</b-input-group-append>
							</b-input-group>     
							
							<span v-show="errors.has('Total')" class="help-block">
								{{ errors.first('Total') }}
							</span>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Notes">
							<b-form-textarea name="Notes" type="text" v-model="form.notes" maxlength="200" :rows="3"
								placeholder="Add more details here..." v-validate="{
									required: true,
									regex: remarksRegex
								}" />
							<span v-show="errors.has('Notes')" class="help-block">
								{{ errors.first('Notes') }}
							</span>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { AssetPoolDistributionUtil } from '@/utils/assetPoolDistributionUtil';
import { ClientAccountUtil } from '@/utils/clientAccountUtil';
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import assetPoolDistributionApi from '@/api/assetPoolDistributionApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'add-asset-pool-distribution',
	components: {
		Loading,
	},
	props: {
		companyOptions: {
			type: Array,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allConnectedCompaniesObj: {
			type: Object,
			required: true,
		},
		allAssetTypesObj: {
			type: Object,
			required: true,
		},
		allConnectionsObj: {
			type: Object,
			required: true,
		},
		allStorageLocationsObj: {
			type: Object,
			required: true,
		},
		allClientAccountsObj: {
			type: Object,
			required: true,
		},
		allAssetPoolDistributionsObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: { ...AssetPoolDistributionUtil.getDefaultDistributionObj() },

			selCompany: { ...config.companyDefaultValue },
			selConnectedCompany: { ...config.companyDefaultValue },
			selConnectedStorageLocation:  { ...config.storageLocationDefaultValue },
			selAssetType: { ...config.assetTypeDefaultValue },

			// Options
			connectedCompanyOptions: [],
			connectedStorageLocationOptions: [],
			assetTypeOptions: [],

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		selCompany: function (newVal) {
			if (newVal && newVal.id) {
				this.onChangeCompany(newVal);
			}
		},
		selConnectedCompany: function (newVal) {
			if (newVal && newVal.id) {
				this.onChangeConnectedCompany(newVal);
			}
		},
		selAssetType: function (newVal) {
			if (newVal && newVal.id) {
				this.reloadExistingAssetPoolDistribution(newVal);

				// preset asset limit
				this.assignAssetLimitFromContract();
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		remarksRegex() {
			return config.remarksRegex;
		},
		isAllowedToEditTotal() {
			return this.selCompany && this.selConnectedCompany 
			&& this.selCompany.id !== null && this.selConnectedCompany.id !== null
			&& this.selCompany.id === this.selConnectedCompany.id;
		}
	},
	mounted() {
		if (this.isSuperAdmin) {
			this.selCompany = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
			this.onChangeCompany();
		}
		this.resetDropDownOptions();
	},
	methods: {
		blockInvalidCharacters(event) {
			// Check if the key pressed corresponds to one of the blocked characters
			if (event.key === '+' || event.key === '-' || event.key === 'e') {
				// Prevent the default behavior (typing the character)
				event.preventDefault();
			}
		},
		decrementValue(fieldName, minVal) {
			if (this.form[fieldName] > minVal) {
				this.form[fieldName] -= 1;
			}
		},
		incrementValue(fieldName, maxVal) {
			if (this.form[fieldName] < maxVal) {
				this.form[fieldName] += 1;
			}
		},

		onChangeCompany() {
			if (this.selCompany !== config.companyDefaultValue) {
				let includeCurrCompany = true;
				let isAssetUserOnly  = this.isSuperAdmin || this.loggedUserCompany.type === 'Asset Service Provider' ? false : true;
				this.connectedCompanyOptions = DropDownItemsUtil.retrieveConnectedCompanies(this.allConnectionsObj, this.selCompany, includeCurrCompany, isAssetUserOnly);
				this.selConnectedCompany = { ...config.companyDefaultValue };
			} else {
				this.resetDropDownOptions();
			}

			// filter entries in asset types
			this.filterAssetTypeOptions(this.selCompany);
		},
		onChangeConnectedCompany() {
			if (this.selConnectedCompany !== config.companyDefaultValue) {
				this.connectedStorageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationsByConnection(
						this.allConnectionsObj,
						this.allStorageLocationsObj,
						this.selCompany.id,
						this.selConnectedCompany.id
					);
				this.selConnectedStorageLocation =  { ...config.storageLocationDefaultValue };
			} else {
				this.resetConnectedStorageLocationOptions();
			}
		},
		reloadExistingAssetPoolDistribution(assetType) {
			let connectedCompanyId = this.selConnectedCompany.id;
			let connectedStorageLocationId = this.selConnectedStorageLocation.id;
			let assetTypeId = assetType.id;

			let filterResults = _.filter(this.allAssetPoolDistributionsObj, (o) => {
				return (
					o.connectedCompanyId === connectedCompanyId &&
					o.connectedStorageLocationId === connectedStorageLocationId &&
					o.assetTypeId === assetTypeId
				);
			});

			if (!_.isEmpty(filterResults)) {
				// reload existing data
				this.form.total = filterResults[0].total;
				this.form.id = filterResults[0].id;
			} else {
				this.form.total = 0;
				this.form.id = null;
			}
		},
		assignAssetLimitFromContract() {
			let sourceId = this.selCompany.id;
			let destinationId = this.selConnectedCompany.id;
			let clientAccountsObj = ClientAccountUtil.getActiveClientAccounts(this.allClientAccountsObj, sourceId, destinationId);

			_.forEach(clientAccountsObj, o => {
				let contractedQuantity = ClientAccountUtil.getContractedQuantity(o, this.selAssetType.id);
				this.form.assetLimit = contractedQuantity;
			});
		},

		filterAssetTypeOptions(selCompany) {
			if (selCompany === config.companyDefaultValue) {
				this.assetTypeOptions = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);
			} else {
				let parentCompanyId = this.getParentCompanyId(this.selCompany);

				let filteredAssetTypesObj = {};
				_.forEach(this.allAssetTypesObj, (assetType, id) => {
					if (assetType.isPublic || (assetType.originId === this.selCompany.id || assetType.originId === parentCompanyId)) {
						filteredAssetTypesObj[id] = assetType;
					}
				});

				this.assetTypeOptions = DropDownItemsUtil.retrieveAssetTypes(filteredAssetTypesObj, true);
			}
		},
		getParentCompanyId(selCompany) {
			let parentCompanyId = '';
			if (selCompany.id && !_.isEmpty(this.allCompaniesObj)) {
				let companyObj = this.allCompaniesObj[selCompany.id];
				parentCompanyId = companyObj.parentCompanyId;
			}
			return parentCompanyId;
		},

		resetDropDownOptions() {
			this.resetConnectedCompanyOptions();
			this.resetConnectedStorageLocationOptions();
		},
		resetConnectedCompanyOptions() {
			this.connectedCompanyOptions = [];
			this.connectedCompanyOptions.push({
				value: { ...config.companyDefaultValue },
				text: ' - Please select - ',
			});
			this.selConnectedCompany = { ...config.companyDefaultValue };
		},
		resetConnectedStorageLocationOptions() {
			this.connectedStorageLocationOptions = [];
			this.connectedStorageLocationOptions.push({
				value:  { ...config.storageLocationDefaultValue },
				text: ' - Please select - ',
			});
			this.selConnectedStorageLocation =  { ...config.storageLocationDefaultValue };
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);

			await this.handleSubmit();
		},
		updateFormValues(form) {
			let distribution = {
				id: form.id ? form.id : null,
				company: this.selCompany.name,
				companyId: this.selCompany.id,
				connectedCompany: this.selConnectedCompany.name,
				connectedCompanyId: this.selConnectedCompany.id,
				connectedStorageLocation: this.selConnectedStorageLocation.name,
				connectedStorageLocationId: this.selConnectedStorageLocation.id,
				type: '',
				assetType: this.selAssetType.name,
				assetTypeId: this.selAssetType.id,
				assetLimit: parseInt(form.assetLimit),
				total: parseInt(form.total),
				addTotal: parseInt(form.addTotal),
				notes: form.notes,
			};

			let companyObj = this.allCompaniesObj[distribution.companyId];
			let connectedCompanyObj = this.allConnectedCompaniesObj[distribution.connectedCompanyId];
			let assetTypeObj = this.allAssetTypesObj[distribution.assetTypeId];
			distribution.type = AssetPoolDistributionUtil.getDistributionType(distribution, companyObj, connectedCompanyObj, assetTypeObj.originId);

			return distribution;
		},
		getParam() {
			return {
				currUserId: this.currUserId,
				currTimeStamp: DateUtil.getCurrentTimestamp(),
				assetPoolDistribution: this.updateFormValues(this.form),
			};
		},
		async handleSubmit() {
			try {
				// show loading indicator
				this.isLoading = true;

				let { data } = await assetPoolDistributionApi.saveAssetPoolDistribution(
					this.getParam()
				);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseSaveAssetPoolDistribution', data.assetPoolDistribution);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error creating Asset Pool Distribution "${this.form.company}". Please try again.`);
				}

			} catch (error) {
				console.log(error);
				this.$toaster.error(`Error creating Asset Pool Distribution "${this.form.company}". Please try again.`);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}			
		},
		onReset() {
			/* Reset our form values */
			this.form = { ...AssetPoolDistributionUtil.getDefaultDistributionObj() };

			this.selCompany = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
			this.onChangeCompany();

			this.selConnectedCompany = { ...config.companyDefaultValue };
			this.selConnectedStorageLocation =  { ...config.storageLocationDefaultValue };
			this.selAssetType = { ...config.assetTypeDefaultValue };

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
};
</script>

<style scoped>
.field-without-label {
	margin-top: 30px;
}
</style>